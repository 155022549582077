

jQuery(window).ready(function($) {

	// Cookie Notice
	var cookieNotice = new CookieAccept();

	

	//FitVids
   $(".video-wrapper").fitVids();

	// For quotes
	$("#slideshow > div:gt(0)").hide();

	setInterval(function() { 
	  $('#slideshow > div:first')
	  	.fadeOut( 1000, function(){
	  		var $this = $(this);
		    $this.next()
		    .fadeIn(1000)
		    .end()
	    	.appendTo('#slideshow')
		    
	  	})	    
	},  8000);

	


 	
	// Menu toggle
	$('#nav-open').click(function(e) {
		e.preventDefault();
		$('#main-nav').addClass('main-nav--open');
	});

	$('#nav-close').click(function(e) {
		e.preventDefault();
		$('#main-nav').removeClass('main-nav--open');
	});




	// Set classes on header and scroll to top buttons on scroll
	var checkPagePosition = function() {
		if ($(window).scrollTop() > 20) {
			$('#header').addClass('header--scrolled');
			$('#main-logo').addClass('scrolled');
			//$('.nav-open').addClass('scrolled');
	//		$('#nav-to-top').addClass('nav-to-top--show');
		} else {
			$('#header').removeClass('header--scrolled');
			$('#main-logo').removeClass('scrolled');
		//	$('.nav-open').removeClass('scrolled');
	//		$('#nav-to-top').removeClass('nav-to-top--show');
		}
	};

	$(window).on('scroll', checkPagePosition);

	// // Animation
	$('.animate').viewportChecker({
		classToAdd: 'fade-up'
	});

	$('.animate-fade').viewportChecker({
		classToAdd: 'fade-in'
	});

	// Smooth scrolling.
	var scrollingoffset = 200;
	if (window.location.hash.length) {
		var target = $(window.location.hash);

		if (target.length) {
			$("html, body").animate({scrollTop: target.offset().top - scrollingoffset}, 1000);
		}
	}

	$('a[href^="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top - scrollingoffset
				}, 1000);
				return false;
			}
		}
	});







	// WP-REST-API post loading
	wp.api.loadPromise.done(function() {


		var posts_lister = $('.posts-lister')
			//,posts_featured_lister = $('.posts-featured-lister')
			, load_more_button = posts_lister.find('.posts-lister__load-more')
			, spinner = posts_lister.find('.posts-lister__spinner')
			, categories_filter = $('.post_categories')
			, search_form = $('#search-form')
			, search_embedded_form = $('#search-embedded-form')
			, selected_category = ''
			, post_type = ''
			, post_filter = ''
			, clear_form = $('.clear-search')
			, posts_collection = {}
			, featured_posts_collection = {}
			, reveal_delay = 200
			, per_page = 12
			;

		var posts_filter = {
			data: {
				per_page_default: 12,
				per_page: 12,
			}
		};




		function add_url_param(url, param, value) {
			
			if(url=='')
				url += '?';
			else
				url += '&';

			url += param + '=' + value;

			return url;
		}

		var fetchPosts = function(callback) {

			posts_collection.fetch(posts_filter).done(function(posts) {
				if (typeof callback === "function") {
					callback(posts);
				}
			});
		};

		var fetchMorePosts = function(callback) {
			if (posts_collection.hasMore()) {
				posts_collection.more().done(function(posts) {
					if (typeof callback === "function") {
						callback(posts);
					}
				});
			}
		};


		var set_page_search = function( page_number ) {

			spinner.addClass('posts-lister__spinner--show');
			
			var target = $('.content--school-search-results-panel');
			if (target.length) {
				$("html, body").animate({scrollTop: target.offset().top - scrollingoffset}, 500);
			}
			posts_filter.data.page = page_number; 
			saveFormData();
			start_search();
		}



		var constructPosts = function(posts) {
			
			var delay = reveal_delay;
			var notification = '';

			if (!posts.length) {
				
				notification = '<p class="notification">There are no questions that match your search critera</p>';

				$(notification).appendTo(posts_lister.find('.posts-lister__posts'));
			}

			posts.forEach(function(post) {

				var rendered_post = renderPost(post);

				delay = delay + reveal_delay;

				setTimeout(function() {
					rendered_post.addClass('post--show');
				}, delay);

			});


			if (!posts_collection.hasMore()) {

				if(notification=='') {

					no_more_posts = '<span>No more questions</span>';

					load_more_button.addClass('posts-lister__load-more--disabled')
						.prop("disabled", true)
						.html(no_more_posts);

					load_more_button.show();

				} else {
					load_more_button.hide();
				}

			} else {

				load_more_button.removeClass('posts-lister__load-more--disabled')
					.prop("disabled", false)
					.html('<span>Load More</span>');

				load_more_button.show();
			}



		};

		


		
		var renderPost = function(post) {

			var image = '';
			var post_html = '';

		
			var sTitle=''
			if (typeof post.title.rendered !== 'undefined') 
				sTitle = post.title.rendered;
			else
				sTitle = post.title;

			var sFeatured ='';

			if(post.featured == 'Recently Added')
			{
				sFeatured = '<span class="post--question__new" >New</span>';
			}

			console.log(post);


			post_html = '<div class="post">'

						+'<div class="post__row_container">'

						+'<div class="image-block">'
						+'<img src="'+ post.featured_image +'">'
						+'</div>'
					
						+'<div class="content-block">'
						+'<h3>'+sTitle+'</h3>'
						+'<p>'+post.excerpt.rendered +'</p>'
						+'</div>'

						+'<div class="content-link">'
						+'<a class="with-arrow" href="'+post.link+'">Read More</a>'
						+'</div>'


						+'</div>'
					
						+'</div>';


			
			$post_html = $(post_html).appendTo(posts_lister.find('.posts-lister__posts'));

			return $post_html;
		};




		var updateCounts = function( featured ) {

			if(!featured)
				featured = 0;


			if (typeof posts_collection.state.totalObjects !== 'undefined') {

				var start = ((posts_collection.state.currentPage - 1) * per_page) + 1;
				var end = Math.min(start + (per_page - 1), posts_collection.state.totalObjects + featured);
				var total_count = posts_collection.state.totalObjects + featured;
				$('.search-results__count__start').text(start);
				$('.search-results__count__end').text(end);
				$('.search-results__total-count').text(total_count);
				$('.search-results__found').fadeIn();
				if(total_count > 0) {
					$('.search-results__showing').fadeIn();
				}
			}
		};




		var saveFormData = function() {

			if (window.sessionStorage) {
				//console.log('saved:' + JSON.stringify(posts_filter.data) );
				sessionStorage.setItem('form_data', JSON.stringify(posts_filter.data));
			}
			
		};


		var populate_questions_form_data = function() {
			
			// Set Category Fields
			$.each( categories_filter, function( key, element ) {
				var $this = $(element);

				//$this.val(posts_filter.data[$this.data('taxonomy')]);
				if(posts_filter.data[$this.data('taxonomy')]) {
					//console.log(posts_filter.data[$this.data('taxonomy')]);
					$this.val(posts_filter.data[$this.data('taxonomy')]);
				}
				
			});

			// Set Search Field
			if (search_form) {
				
				search_input = $('.search-form__input');
				search_input.val(posts_filter.data.search);
				
				if(search_input.val()!=''){
					search_input.addClass('search-form__input--dirty');
				}				
				
			}

		};




		var add_search_taxonomy = function() {
			
			// Set Category Fields
			$.each( categories_filter, function( key, element ) {
				var $this = $(element);

				if($this.val() !=0 )
				{
					posts_filter.data[$this.data('taxonomy')] = $this.val();
				}
				else
				{
					delete posts_filter.data[$this.data('taxonomy')];
				}

			});

		};




		var start_search = function() {

			$('.post-results__found').hide();

			if (window.sessionStorage && sessionStorage.getItem('form_data') !== null) {
				posts_filter.data = JSON.parse(sessionStorage.getItem('form_data'));
				populate_questions_form_data();
			}
			
			$('.posts-lister__posts').empty();

			fetchPosts(function(posts) {
				spinner.removeClass('posts-lister__spinner--show');
				load_more_button.hide();
				constructPosts(posts);
				updateCounts();
				createPaginator();
			});
			
		};


		var createPaginator = function() {
			paginator = $('.pagination').pagination({
				items: posts_collection.state.totalObjects
				, itemsOnPage: per_page
				, currentPage: posts_collection.state.currentPage
				, onPageClick: function(page_number, event) {
					event.preventDefault();
					set_page_search(page_number);
				}
				, displayedPages: 3
				, edges: 1
				, ellipsePageSet: false
				, cssStyle: ''
				, nextText: '»'
				, prevText: '«'
			});
		};



		// If on search results page

		if (posts_lister.length) {

			post_type = posts_lister.data('post-type');
			spinner.addClass('posts-lister__spinner--show');
			load_more_button.hide();
			posts_collection = new wp.api.collections.Articles();
			

			start_search();

			if (load_more_button) {
				load_more_button.on('click', function(e) {
					e.preventDefault();
					spinner.addClass('posts-lister__spinner--show');
					load_more_button.hide();

					saveFormData();

					fetchMorePosts(function(posts) {
						spinner.removeClass('posts-lister__spinner--show');

						constructPosts(posts);
						updateCounts();
					});
				}); 
			}

	


			if (search_form) {

				search_form.submit(function(e) {

					e.preventDefault();
					$this = $(this);

					posts_lister.find('.posts-lister__posts').empty();
					spinner.addClass('posts-lister__spinner--show')		
					load_more_button.hide();			

					var search_query = $this.find('.search-form__input').val();
					
					if (search_query) {
						posts_filter.data.search = search_query;
					} else {
						delete posts_filter.data.search;
					}

					// Adds the category taxonomies to the search
					add_search_taxonomy();
					posts_filter.data.page = 1; 
					saveFormData();

					start_search();
					
				});
			}
		}
		
		
		

	});





});
